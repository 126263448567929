import layout from "@/views/layout/index.vue";
import gerUserModule from "@/views/GerInquiryOrderManage/userModule/index.vue";
const gerThreeDManage = () => import("@/views/GerInquiryOrderManage/threeDManage.vue");
const gerAllocationConfiguration = () => import("@/views/GerInquiryOrderManage/allocationConfiguration.vue");
const gerInquiryStatistics = () => import("@/views/GerInquiryOrderManage/inquiryStatistics.vue");
const gerOrderTimeliness = () => import("@/views/GerInquiryOrderManage/orderTimeliness.vue");
const gerMarketingordertracking = () => import("@/views/GerInquiryOrderManage/marketingordertracking.vue");
const gerInquiry = () => import("@/views/GerInquiryOrderManage/inquiry/index.vue");
const gerInquiryManage = () => import("@/views/GerInquiryOrderManage/inquiry/inquiryManage.vue");
const gerInquiryDetail = () => import("@/views/GerInquiryOrderManage/inquiry/inquiryDetail.vue");
const gerOrder = () => import("@/views/GerInquiryOrderManage/order/index.vue");
const gerLinkOrder = () => import("@/views/GerInquiryOrderManage/linkOrder/index.vue");
const gerOrderManage = () => import("@/views/GerInquiryOrderManage/order/orderManage.vue");
const gerLinkOrderManage = () => import("@/views/GerInquiryOrderManage/linkOrder/linkOrderManage.vue");
const gerRefundForm = () => import("@/views/GerInquiryOrderManage/order/refundForm.vue");
const gerOrderDetail = () => import("@/views/GerInquiryOrderManage/order/orderDetail.vue");
const gerUploadDraft = () => import("@/views/GerInquiryOrderManage/order/uploadDraft.vue");
const gerFinancialOffline = () => import("@/views/financial/financialOffline.vue");
const gerInquiryBacklogStatistics = () => import("@/views/GerInquiryOrderManage/inquiryBacklogStatistics.vue");

export default {
	path: "/GerInquiryOrderManage",
	component: layout,
	name: "GerInquiryOrderManage",
	meta: {
		title: "德国询盘订单管理",
		icon: "el-icon-tickets",
	},
	children: [
		{
			path: "inquiry",
			name: "gerInquiry",
			component: gerInquiry,
			meta: {
				title: "询盘",
			},
			children: [
				{
					path: "inquiryManage",
					name: "gerInquiryManage",
					component: gerInquiryManage,
					meta: {
						title: "询盘列表",
					},
				},
				{
					path: ":id",
					name: "gerInquiryDetail",
					component: gerInquiryDetail,
					hidden: true,
					meta: {
						title: "询盘详情",
					},
				},
			],
		},
		{
			path: "linkOrder",
			name: "gerLinkOrder",
			component: gerLinkOrder,
			meta: {
				title: "链接订单",
			},
			children: [
				{
					path: "linkOrderManage",
					name: "gerLinkOrderManage",
					component: gerLinkOrderManage,
					meta: {
						title: "链接订单",
					},
				},
				{
					path: "uploadDraft",
					name: "gerUploadDraft",
					component: gerUploadDraft,
					hidden: true,
					meta: {
						title: "图稿上传",
					},
				},
				{
					path: ":id",
					name: "gerOrderDetail",
					component: gerOrderDetail,
					hidden: true,
					meta: {
						title: "订单详情",
					},
				},
			],
		},
		{
			path: "order",
			name: "gerOrder",
			component: gerOrder,
			meta: {
				title: "订单",
			},
			children: [
				{
					path: "orderManage",
					name: "gerOrderManage",
					component: gerOrderManage,
					meta: {
						title: "直接订单",
					},
				},
				{
					path: "uploadDraft",
					name: "gerUploadDraft",
					component: gerUploadDraft,
					hidden: true,
					meta: {
						title: "图稿上传",
					},
				},
				{
					path: ":id",
					name: "gerOrderDetail",
					component: gerOrderDetail,
					hidden: true,
					meta: {
						title: "订单详情",
					},
				},
			],
		},
		{
			path: "gerThreeDManage",
			name: "gerThreeDManage",
			component: gerThreeDManage,
			meta: {
				title: "3D订单管理",
			},
		},
		{
			path: "allocationConfiguration",
			name: "gerAllocationConfiguration",
			component: gerAllocationConfiguration,
			meta: {
				title: "分配配置",
			},
		},
		{
			path: "inquiryStatistics",
			name: "gerInquiryStatistics",
			component: gerInquiryStatistics,
			meta: {
				title: "询盘订单统计",
			},
		},
		//未成单
		{
			path: "inquiryBacklogStatistics",
			name: "gerInquiryBacklogStatistics",
			component: gerInquiryBacklogStatistics,
			meta: {
				title: "询盘未成单原因占比统计",
			},
		},
		{
			path: "",
			redirect: "userModule",
		},
		{
			path: "userModule",
			name: "gerUserModule",
			component: gerUserModule,
			meta: {
				title: "用户模块",
			},
		},
		{
			path: "orderTimeliness",
			name: "gerOrderTimeliness",
			component: gerOrderTimeliness,
			meta: {
				title: "订单时效管理",
			},
		},
		{
			path: "marketingordertracking",
			name: "gerMarketingordertracking",
			component: gerMarketingordertracking,
			meta: {
				title: "营销订单追踪",
			},
		},
		{
			path: "/financialOffline",
			name: "gerFinancialOffline",
			component: gerFinancialOffline,
			meta: {
				title: "财务线下收款",
			},
		},
	],
};
