const getters = {
	language: (state) => state.app.language,
	itemLang: (state) => state.app.itemLang,
	permission_routes: (state) => state.permission.routes,
	visitedViews: (state) => state.tagsView.visitedViews,
	cachedViews: (state) => state.tagsView.cachedViews,
	token: (state) => state.auth.token,
	roleId: (state) => (state.auth.userInfo ? state.auth.userInfo.roles.map((item) => item.id) : null),
	deptId: (state) => (state.auth.userInfo ? state.auth.userInfo.roles.map((item) => item.deptId) : null),
	isAdmin: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("admin");
	},
	//发货员
	shipping: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Shipping");
	},
	//跟单员
	merchandiser: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Merchandiser");
	},
	//采购员
	Purchase: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Purchase");
	},
	//下单元
	orderplacer: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("orderplacer");
	},
	//生产员
	Factory: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Factory");
	},
	isDesignerAdmin: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Designeradmin");
	},
	isThreedimensionaladmin: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Threedimensionaladmin");
	},
	artworkUserType: (state) => {
		return state.auth.userInfo.artworkUserType;
	},
	userName: (state) => state.auth.userInfo.userName,
	typeId: (state) => state.auth.typeId,
	userInfo: (state) => state.auth.userInfo,
	isSalesMan: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Salesman");
	},
	isArtworkDesignerAdmin: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-DesignerAdmin") || rolesName.includes("admin");
	},
	isArtworkDesignerGroupLeader: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-DesignerGroupLeader");
	},
	isArtworkDesigner: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-Designer");
	},
	isArtworkTwoDGroupLeader: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-TwoDGroupLeader");
	},
	isArtworkTwoDDesigner: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-TwoDDesigner");
	},
	isArtworkThreeDGroupLeader: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-ThreeDGroupLeader");
	},
	isArtworkThreeDDesigner: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-ThreeDDesigner");
	},
	isArtworkThreedimensionalAdmin: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-ThreedimensionalAdmin");
	},
	isArtworkThreedimensionalGroupLeader: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-ThreedimensionalGroupLeader");
	},
	isArtworkThreedimensionalDesigner: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-ThreedimensionalDesigner");
	},
	isArtworkCheckAdmin: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-CheckAdmin");
	},
	isArtworkSalesMan: (state) => {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("Artwork-SalesMan");
	},
	isYxZz(state) {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("联盟营销组长");
	},
	isYxZy(state) {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return (
			rolesName.includes("联盟营销普通员工") &&
			!rolesName.includes("市场营销经理") &&
			!rolesName.includes("联盟营销组长")
		);
	},
	isYxJl(state) {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return (
			rolesName.includes("营销中心经理") ||
			rolesName.includes("联盟营销经理") ||
			rolesName.includes("市场营销经理")
		);
	},
	isSxs(state) {
		const rolesName = state.auth.userInfo.roles.map((item) => item.roleName);
		return rolesName.includes("实习生");
	},
	isMobile() {
		const userAgent = navigator.userAgent.toLowerCase();
		const mobileKeywords = ["android", "iphone", "ipod", "ipad", "windows phone", "mobile"];
		return mobileKeywords.some((keyword) => userAgent.includes(keyword));
	},
	lang: () => {
		return {
			design: {
				chooseProText: "Choose Products",
				changeProText: "Change Products",
				getPriceTip: "Go to a new page for quote details.",
				getPrice: "Get Price",
				changeColor: "Change Color",
				changeDesignTip: "Tip for Change Products",
				tipMain:
					"Your current design has not been saved, do you need to go to the new design system to continue designing?",
				nowDesign: "Continue with Current Design",
				newDesign: "Start with New Design",
				allProducts: "All Products",
				ProductKeywords: "Product Keywords...",
				priceFrom: "Prices from",
				to: "to",
				from: "from",
				listPrices: "List Prices:",
				onSale: "On sale",
				allCliparts: "Cliparts Gallery",
				myClipart: "My Cliparts",
				upAndRep: "Upload & Replace",
				saveYourDesign: "Save Your Design",
				enterName: "Enter Design Name",
				saveDesign: "Save Design",
				designText:
					"Once saved, share with others and access it from anywhere! Your email is safe and secure. Read our",
				privacy: "privacy policy",
				saveChanges: "Save Changes to",
				emailAddress: "We found an existing design with the same name and email address.",
				update: "Update Design",
				saveNew: "Save as New",
				continue: "Continue",
				continueSave: "Continue with Email Save",
				yourEmail: "Your email is secure with us",
				myFavorite: "My Favorite",
				please: "Please select Move element",
				delTemplate: "Are you sure you want to delete the template?",
				delFolder: "Are you sure you want to delete the folder?",
				tips: "Tips",
				cancel: "Cancel",
				warning: "warning",
				common: "common",
				favorites: "Favorites",
				uploadCliparts: "Upload your Cliparts",
				drag: "Drag file here, or",
				browse: "browse",
				rename: "rename",
				delete: "delete",
				myUpload: "My Upload",
				all: "All",
				create: "Create",
				moveTo: "Move to",
				onlyUploaded: "Only JPG/PNG files and no more than 10M can be uploaded",
				upload: "Upload",
				category: "Category",
				saveCategory: "Save to [My Upload]], or create your own category.",
				ok: "OK",
				reset: "Reset",
				categoryText: "Create your own category",
				pubilc: "Public Safety",
				myCreation: "My Creation",
				templates: "Templates",
				cliparts: "Cliparts",
				loading: "loading",
				officalCliparts: "Offical Cliparts",
				clipartsAll: "All Cliparts",
				editCliparts: "Edit Cliparts",
				editSuggest: "Please replace your current clipart with the below clipart:",
				current: "Current",
				comfirm: "confirm",
				imageColor: "Image Color",
				singleColor: "Single Color",
				chooseColor: "Choose Which Color to Change",
				selectColor: "Select color:",
				chooseReplacedColor: "Choose the replaced color:",
				addText: "Add Text",
				addCliparts: "Add Cliparts",
				layers: "Layers",
				myDesign: "My Design",
				what: "What’s Next?",
				addToDesign: "Add Text",
				addCurvedToDesign: "Add Curved Text",
				enterText: "Enter text here",
				font: "Font",
				textColor: "Text Color",
				rotation: "Rotation",
				spacing: "Spacing",
				textShape: "Text Shape",
				textSize: "Text Size",
				outline: "Outline",
				none: "None",
				textCurve: "Text Curve",
				textAlignment: "Text Alignment",
				textDecoration: "Text Decoration",
				backPrevious: "Back to Previous",
				recentlyFonts: "Recently Used Fonts",
				done: "Done",
				chooseShape: "Choose shape settings",
				down: "Down",
				up: "Up",
				chooseOutline: "Choose outline thickness",
				veryThick: "Very Thick",
				chooseOutlineColor: "Choose outline color",
				removeOutline: "Remove Outline",
				currentArt: "Current Art",
				replace: "Replace",
				group: "Group 1",
				chooseFile: "Choose a file to upload",
				maxFile: "Max. File Size: 10MB",
				imgJPG: ".JPG.JPEG.PNG.SVG.GIF.BMP.PDF.PSD.AI",
				remark: "Remark: If you need to upload other types of images, please contact us. We will solve it for you in time.",
				uploadHistory: "Upload History",
				clearAll: "Clear All",
				normal: "normal",
				curve: "curve",
				arch: "arch",
				bridge: "bridge",
				valley: "valley",
				pinch: "pinch",
				bulge: "bulge",
				perspective: "perspective",
				pointed: "pointed",
				downward: "downward",
				upward: "upward",
				cone: "cone",
				veryThin: "Very Thin",
				thin: "Thin",
				medium: "Medium",
				thick: "Thick",
				editText: "Edit Text",
				fontColors: "Font Colors",
				textOutline: "Text Outline",
				deleteAll: "Delete all history?",
				sizeError: "The size of the uploaded profile picture should not exceed 10MB!",
				imgError:
					"Error uploading file type, Accepted File Types (JPG、JPEG、PNG、SVG、GIF、BMP、PDF、PSD、AI)",
				pleaseToast: "Please select the color you want to replace",
				menu: "Menu",
				talkPerson: "Talk to A Real Person",
				chatNow: "Chat Now",
				myOrders: "My Orders",
				myProfile: "My Profile",
				myAddress: "My Address",
				logIn: "Log in/Join Free",
				cart: "Cart",
				tipsText: "If you want to make a more detailed design, please use our PC terminal to handle it.",
				skip: "Skip",
				howStarted: "How to quickly get started",
				withSystem: "with our design system?",
				letStart: "Let's Start",
				selectTemplate: "Select your favorite product template.",
				next: "Next",
				addRicher: "Add richer text and cliparts to your design.",
				utilizeDesign: "Utilize the powerful functional area to optimize your design.",
				clickProduct: "Click the 'Get Price' button to quote your design or product.",
				logOthers: "Log in to your account to save your designs and share them with others.",
				designNow: "Design Now",
				deleteDesign: "Delete current design?",
				oops: "Oops",
				useOther: "Use other related words.",
				contact: "Contact our customer service",
				createCliparts: "Create or upload your own personalized cliparts.",
				areQuit: "Are You Sure to Quit?",
				designLost: "If you quit now, your design will be lost.",
				leaveNow: "Leave Now (No Saving)",
				signUp: "Sign up/Log in to Save it",
				undo: "Undo",
				redo: "Redo",
				text: "Text",
				save: "Save",
				share: "Share",
				shareDesign: "Share Your Design",
				shareThemselves: "Share this link to allow others to see your design and order a copy for themselves.",
				copy: "Copy",
				remark2: "Remark",
				pleaseNote: "Please note that the sharing link is valid for 60 days.",
				productTemplate: "No Product Template",
				chooseQuoting: "Choose a product template that suits your design and start quoting.",
				selectTemplate2: "Select Template",
				goSystem: "Go to Quote System",
				recentlyUsed: "Recently Used",
				areTemplate: "Are you sure you want to replace your design with our template?",
				hint: "Hint",
				cancelFavorite: "Cancel Favorite",
				addFavorite: "Add Favorite",
				templateCateName: "Offical Templates",
				allTemplates: "All Templates",
				up2: "UP",
				down2: "DOWN",
				brushSize: "Brush Size",
				startDrawing: "Start drawing",
				stopDrawing: "Stop drawing",
				pleaseSelect: "please select",
				filp: "Flip",
				replace2: "replace",
				copy2: "copy",
				mirror: "mirror",
				center: "center",
				color: "color",
				fit: "Fit",
				zoomOut: "Zoom out",
				currentZoom: "Current zoom",
				fitWindow: "Fit to window",
				zoomOn: "Zoom on",
				Browse: "Browse",
				size: "Size",
				areYouSure: "Are you sure you want to leave the page",
				thankCustom: "Thank You Custom",
				elementError: "element #workspace is missing, plz check!",
				moveLayer: "Move the layer",
				search: "Search...",
			},
		};
	},
	getGmail: (state) => state.gmail,
	getEmailPage: (state) => state.emailPage,
	getEmailPageSize: (state) => state.emailPageSize,
	getDefaultNewSign: (state) => state.defaultNewSign,
	getDefaultReplySign: (state) => state.defaultReplySign,
	getEmailDetailBack: (state) => state.emailDetailBack,
};
export default getters;
