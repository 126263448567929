import request from "@/utils/request";

// 获取所有货币

export function getCurrency() {
	return request({
		url: "/quote/currency/getCurrency",
		method: "post",
	});
}
