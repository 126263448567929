<template>
	<el-breadcrumb class="app-breadcrumb" separator="/">
		<transition-group class="breadcrumContentBox" name="breadcrumb">
			<el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
				<span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{
					$t(item.meta.title)
				}}</span>
				<a v-else @click.prevent="handleLink(item)">{{ $t(item.meta.title) }}</a>
			</el-breadcrumb-item>
		</transition-group>
	</el-breadcrumb>
</template>

<script>
export default {
	name: "Breadcrumb",
	data() {
		return {
			levelList: null,
		};
	},
	watch: {
		$route(route) {
			// if you go to the redirect page, do not update the breadcrumbs
			if (route.path.startsWith("/redirect/")) {
				return;
			}
			this.getBreadcrumb();
		},
	},
	created() {
		this.getBreadcrumb();
	},
	computed: {
		routes() {
			return this.$store.getters.permission_routes;
		},
	},
	methods: {
		getBreadcrumb() {
			let matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
			const first = matched[0];
			if (!this.isDashboard(first)) {
				matched = [{ path: "/dashboard", name: "dashboard", meta: { title: "leftSidebar.homePage" } }].concat(
					matched,
				);
			}
			this.levelList = matched.filter((item) => item.meta && item.meta.title && item.meta.breadcrumb !== false);
		},
		isDashboard(route) {
			const name = route && route.name;
			if (!name) {
				return false;
			}
			return name.trim().toLocaleLowerCase() === "dashboard".toLocaleLowerCase();
		},
		backPage(name) {
			if (name === "memberManage") {
				this.$store.commit("app/set_showUserDetail", false);
			}
		},
		handleLink(item) {
			let findItem;
			const fn = (list, name) => {
				if (findItem) {
					return false;
				} else {
					list.forEach((item) => {
						if (item.name === name) {
							console.log(item);
							findItem = item;
						}
						if (item.children) {
							fn(item.children, name);
						}
					});
				}
			};
			fn(this.routes, item.name);
			if (findItem && findItem.children) {
				this.$router.push({
					name: findItem.children[0].name,
				});
				this.backPage(findItem.children[0].name);
			} else {
				this.$router.push({
					name: findItem.name,
				});
				this.backPage(findItem.name);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
	display: inline-block;
	white-space: nowrap; /* 禁止换行 */
	overflow-x: auto;
	overflow-y: hidden;
	height: 50px;
	font-size: 14px;
	line-height: 50px;
	margin-left: 8px;
	.no-redirect {
		color: #97a8be;
		cursor: text;
	}

	a {
		color: #fff;
	}
	.breadcrumContentBox {
		display: flex;
		flex-wrap: nowrap;
	}
}
</style>
