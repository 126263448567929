import store from "@/store";
import { getToken, getLiyiAdmin, getLiyiUserName } from "@/utils/auth";
import { Message } from "element-ui";
import Vue from "vue";
import VueRouter from "vue-router";
import { constantRoutes } from "@/router/common";
import { userAuthList, liyiHomePath } from "@/utils/liyiStatic";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

//404页面单独拿出来单独添加
const notFound = [
	{
		path: "/:pathMatch(.*)*",
		redirect: "notFound",
		hidden: true,
	},
];

const router = new VueRouter({
	mode: "history",
	routes: constantRoutes,
	base: process.env.BASE_URL,
});
let flag = false; // 作为是否刷新页面重调路由的标志
const whiteList = ["login", "fileList", "/factoryScreen/home", "/factoryScreen/homeiframe", "/factoryScreen/redirect"];
router.beforeEach(async (to, from, next) => {
	let token = getToken();
	//造一个假的登录页面及假的权限
	if (liyiHomePath.indexOf(to.path) >= 0) {
		if (!getLiyiAdmin()) {
			if (to.path == "/liyiRegister") {
				next();
			} else {
				next("/liyiRegister");
			}
		} else {
			if (to.path == "/liyiRegister") {
				next({ path: "/liyiSetting" });
			} else {
				if (flag) {
					next();
				} else {
					flag = true;
					let testData = {
						userAuthList: userAuthList,
						userInfo: { userName: getLiyiUserName() },
						flag: true,
					};
					// 模拟layout菜单
					store.commit("auth/SET_USER_INFO", { userName: getLiyiUserName() });
					const accessRoutes = await store.dispatch("permission/generateRoutes", testData);
					router.addRoutes(accessRoutes.concat(notFound));
					// router.addRoute(accessRoutes[0])
					// router.addRoute(notFound)
					next({
						...to,
						replace: true,
					});
				}
			}
		}
	} else {
		//路由跳转逻辑:如果不存再token,去其他页面跳转到登录页,如果存在token去往登陆页跳转到首页
		if (!token) {
			console.log("没有路由", whiteList, to.name, to.path, whiteList.indexOf(to.name));
			if (whiteList.indexOf(to.name) >= 0) {
				next();
			} else {
				//注入白名单
				if (whiteList.indexOf(to.path) >= 0) {
					next();
				} else {
					next(`/ly/login`);
				}
			}
		} else {
			// 获取业务员
			if (to.path === "/login") {
				next({
					path: "/",
				});
			} else {
				const hasRoles = store.getters.roleId;
				if (hasRoles) {
					if (to.matched.length === 0) {
						return next({
							path: "/notFound",
						});
					}
					next();
				} else {
					//同时请求加快速度-2024-2-19----xwc
					Promise.all([
						store.dispatch("auth/getUserInfo"),
						store.dispatch("app/getAllSales"),
						store.dispatch("app/getQuoteList"),
						store.dispatch("app/getProjectList"),
						store.dispatch("app/getCateList"),
						store.dispatch("app/getMarks"),
						store.dispatch("app/getCountry"),
						store.dispatch("app/getProjectQuoteCateList"),
						store.dispatch("manage/updateUnreadData"),
						store.dispatch("manage/updateGerUnreadData"),
						store.dispatch("manage/getUnreadQtyData"),
					])
						.then(async (result) => {
							const userData = result[0];
							const accessRoutes = await store.dispatch("permission/generateRoutes", userData);
							router.addRoutes(accessRoutes.concat(notFound));
							next({
								...to,
								replace: true,
							});
						})
						.catch(async (error) => {
							await store.dispatch("auth/resetToken");
							Message.error(error || "Has Error");
							next({
								name: "login",
							});
						});
				}
			}
			if (to.name === "linkOrderManage" || to.name === "orderManage") {
				// 订单缓存条件处理 当前页面刷新缓存筛选条件不重置，路由切换清空筛选条件
				if (from.name === null) {
					// 页面刷新逻辑
					console.log("页面刷新");
					store.commit("manage/changeResetForm", false);
				}
				// else {
				// 	// 路由切换逻辑
				// 	console.log('路由切换')
				// 	store.commit("manage/changeResetForm", true);
				// }
			}
		}
	}
});

export default router;
