const layout = () => import("@/views/layout/index.vue");
const audience = () => import("@/views/set/audience.vue");
const trackingTimeManagement = () => import("@/views/set/trackingTimeManagement.vue");
const authorityManage = () => import("@/views/set/authorityManage.vue");
const freightManage = () => import("@/views/set/freightManage.vue");
const statusSetting = () => import("@/views/set/statusSetting.vue");
const languageManage = () => import("@/views/set/languageManage.vue");
const authorityList = () => import("@/views/set/authorityList.vue");
const country = () => import("@/views/set/country.vue");
const officeManagement = () => import("@/views/set/officeManagement.vue");
const weChatNotifiManage = () => import("@/views/set/weChatNotifiManage.vue");
const timedTask = () => import("@/views/set/timedTask.vue");
const notificationManagement = () => import("@/views/set/notificationManagement.vue");
export default {
	path: "/set",
	name: "set",
	component: layout,
	meta: {
		title: "基础设置管理",
		icon: "el-icon-setting",
	},
	children: [
		{
			path: "country",
			component: country,
			name: "country",
			meta: {
				title: "国家管理",
			},
		},
		{
			path: "timedTask",
			name: "timedTask",
			component: timedTask,
			meta: {
				title: "定时任务",
			},
		},
		{
			path: "authorityList",
			component: authorityList,
			name: "authorityList",
			meta: {
				title: "权限管理",
			},
		},
		{
			path: "authorityManage",
			component: authorityManage,
			name: "authorityManage",
			meta: {
				title: "账户权限管理",
			},
		},
		{
			path: "freightManage",
			component: freightManage,
			name: "freightManage",
			meta: {
				title: "快递管理",
			},
		},
		{
			path: "statusSetting",
			component: statusSetting,
			name: "statusSetting",
			meta: {
				title: "待办跳转管理",
			},
		},
		{
			path: "languageManage",
			component: languageManage,
			name: "languageManage",
			meta: {
				title: "语言管理",
			},
		},
		{
			path: "audience",
			component: audience,
			name: "audience",
			meta: {
				title: "人群管理",
			},
		},
		{
			path: "trackingTimeManagement",
			component: trackingTimeManagement,
			name: "trackingTimeManagement",
			meta: {
				title: "追踪时间管理",
			},
		},
		{
			path: "notificationManagement",
			component: notificationManagement,
			name: "notificationManagement",
			meta: {
				title: "通知管理",
			},
		},
		{
			path: "officeManagement",
			component: officeManagement,
			name: "officeManagement",
			meta: {
				title: "办公室管理",
			},
		},
		{
			path: "weChatNotifiManage",
			component: weChatNotifiManage,
			name: "weChatNotifiManage",
			meta: {
				title: "企业微信通知管理",
			},
		},
	],
};
