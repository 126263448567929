import layout from "@/views/layout/index.vue";

const dashboard = () => import("@/views/dashboard.vue");
const login = () => import("@/views/login.vue");
const factoryScreenHome = () => import("@/views/factoryScreen/home/index.vue");
const factoryScreenHomeIframe = () => import("@/views/factoryScreen/homeIframe/index.vue");
const factoryScreenRedirect = () => import("@/views/factoryScreen/redirect/index.vue");

const liyiLogin = () => import("@/views/liyiLogin.vue");
const fileList = () => import("@/views/fileList.vue");
// const jobInformation = () => import("@/views/liyiHome/jobInformation.vue");
// const commonProblem = () => import("@/views/liyiHome/commonProblem.vue");
const printAndDispatchInvoice = () => import("@/views/print/printAndDispatchInvoice.vue");
const interiorProblemPrint = () => import("@/views/print/interiorProblemPrint.vue");
const purchasePrint = () => import("@/views/print/purchasePrint.vue");
const ProductOrders = () => import("@/views/print/ProductOrders.vue");
const refundList = () => import("@/views/print/refundList.vue");
const dispatchList = () => import("@/views/print/dispatchList.vue");
const orderPrint = () => import("@/views/print/orderPrint.vue");
const orderPrintCopy = () => import("@/views/print/orderPrintCopy.vue");
const orderPrintByCreditMemo = () => import("@/views/print/orderPrintByCreditMemo.vue");

const inquiryPrint = () => import("@/views/print/inquiryPrint.vue");
const linkQuotation = () => import("@/views/print/linkQuotation.vue");
const AbnormalorderPrint = () => import("@/views/print/AbnormalorderPrint.vue");
const questionaryPrint = () => import("@/views/print/questionaryPrint.vue");
const contractFile = () => import("@/views/print/contractFilePrint.vue");
const customsDeclarationFile = () => import("@/views/print/customsDeclarationFilePrint.vue");
const authorizationPdf = () => import("@/views/print/authorizationPdf.vue");
const fdInquiryOrderManageMonthInvoicePrint = () => import("@/views/print/fdInquiryOrderManageMonthInvoicePrint.vue");
// const provePdf = () => import("@/views/print/provePdf.vue");
const upsCopy = () => import("@/views/print/upsCopy.vue");
const packingSlipFile = () => import("@/views/print/packingSlipFilePrint.vue");
const invoiceFile = () => import("@/views/print/invoiceFilePrint.vue");
const delayedPrinting = () => import("@/views/print/delayedPrinting.vue");
const shippingPrint = () => import("@/views/print/shippingPrint.vue");
const packingListPrint = () => import("@/views/print/packingListPrint.vue");
const salesContract = () => import("@/views/print/salesContract.vue");
const UPSPrint = () => import("@/views/print/UPSPrint.vue");
const errorPage = () => import("@/views/errorPage/404.vue");
const changePassWord = () => import("@/views/changePassWord.vue");
const CurrencyConverter = () => import("/src/components/CurrencyConverter");
const uploadThreeDLib = () => import("@/views/drawingSystem/uploadThreeDLibrary.vue");
export const constant = [
	{
		path: "/",
		name: "index",
		component: layout,
		redirect: "/dashboard",
		children: [
			{
				path: "dashboard",
				name: "dashboard",
				component: dashboard,
				meta: {
					title: "leftSidebar.homePage",
					icon: "el-icon-s-data",
					affix: true,
				},
			},
		],
	},
	{
		path: "/liyiRegister",
		name: "liyiRegister",
		hidden: true,
		component: liyiLogin,
	},
	{
		path: "/fileList",
		name: "fileList",
		hidden: true,
		component: fileList,
	},
	{
		path: "/:id/login",
		name: "login",
		hidden: true,
		component: login,
		beforeEnter: (to, from, next) => {
			const excludedIds = ["ly", "qm", "google", "cs", "sc"];
			if (!excludedIds.includes(to.params.id)) {
				next({ name: "login", params: { id: "ly" } });
			} else {
				next();
			}
		},
	},
	{
		path: "/print/printAndDispatchInvoice",
		name: "/print/printAndDispatchInvoice",

		component: printAndDispatchInvoice,
	},
	{
		path: "/print/interiorProblemPrint",
		name: "/print/interiorProblemPrint",

		component: interiorProblemPrint,
	},
	{
		path: "/print/purchasePrint",
		component: purchasePrint,
		name: "/print/purchasePrint",
	},
	{
		path: "/print/ProductOrders",
		component: ProductOrders,
		name: "/print/ProductOrders",
	},
	{
		path: "/print/refundList",
		component: refundList,
		name: "/print/refundList",
	},
	{
		path: "/print/dispatchList",
		component: dispatchList,
		name: "/print/dispatchList",
	},
	{
		path: "/print/orderPrint",
		component: orderPrint,
		name: "/print/orderPrint",
	},
	{
		path: "/print/orderPrintCopy",
		component: orderPrintCopy,
		name: "/print/orderPrintCopy",
	},
	{
		path: "/print/orderPrintByCreditMemo",
		component: orderPrintByCreditMemo,
		name: "/print/orderPrintByCreditMemo",
	},
	{
		path: "/print/inquiryPrint",
		component: inquiryPrint,
		name: "/print/inquiryPrint",
	},
	{
		path: "/print/linkQuotation",
		component: linkQuotation,
		name: "/print/linkQuotation",
	},
	{
		path: "/print/AbnormalorderPrint",
		component: AbnormalorderPrint,
		name: "/print/AbnormalorderPrint",
	},
	{
		path: "/print/questionaryPrint",
		component: questionaryPrint,
		name: "QuestionaryPrint",
	},
	{
		path: "/print/contractFilePrint",
		component: contractFile,
		name: "ContractFile",
	},
	{
		path: "/print/customsDeclarationFilePrint",
		component: customsDeclarationFile,
		name: "CustomsDeclarationFile",
	},
	{
		path: "/print/packingSlipFilePrint",
		component: packingSlipFile,
		name: "PackingSlipFile",
	},
	{
		path: "/print/invoiceFilePrint",
		component: invoiceFile,
		name: "InvoiceFile",
	},
	{
		path: "/upload/uploadThreeDLib/:id?/:index?",
		component: uploadThreeDLib,
		name: "uploadThreeDLib",
		props: true,
	},
	{
		path: "/print/delayedPrinting",
		component: delayedPrinting,
		name: "delayedPrinting",
	},
	{
		path: "/print/shippingPrint",
		component: shippingPrint,
		name: "shippingPrint",
	},
	{
		path: "/print/packingListPrint",
		component: packingListPrint,
		name: "packingListPrint",
	},
	{
		path: "/print/salesContract",
		component: salesContract,
		name: "salesContract",
	},
	{
		path: "/print/UPSPrint",
		component: UPSPrint,
		name: "UPSPrint",
	},
	// {
	// 	path: "/print/provePdf",
	// 	component: provePdf,
	// 	name: "provePdf"
	// },
	{
		path: "/print/authorizationPdf",
		component: authorizationPdf,
		name: "authorizationPdf",
	},

	{
		path: "/print/fdInquiryOrderManageMonthInvoicePrint",
		component: fdInquiryOrderManageMonthInvoicePrint,
		name: "fdInquiryOrderManageMonthInvoicePrint",
	},
	{
		path: "/print/upsCopy",
		component: upsCopy,
		name: "upsCopy",
	},

	{
		path: "/notFound",
		name: "notFound",
		hidden: true,
		component: errorPage,
	},
	{
		path: "/change",
		name: "change",
		component: layout,
		hidden: true,
		children: [
			{
				path: "changePassWord",
				component: changePassWord,
				name: "changePassWord",
			},
		],
	},
	{
		path: "/factoryScreen/home",
		component: factoryScreenHome,
		name: "home",
	},
	{
		path: "/factoryScreen/homeiframe",
		component: factoryScreenHomeIframe,
		name: "homeiframe",
	},
	{
		path: "/factoryScreen/redirect",
		component: factoryScreenRedirect,
		name: "redirect",
	},
	{
		hidden: true,
		path: "/currencyConverter", // 汇率换算
		component: CurrencyConverter,
		name: "currencyConverter",
	},
];
/*
{
		path: "/:id/login",
		name: "login",
		hidden: true,
		component: login,
		beforeEnter: (to, from, next) => {
			const excludedIds = ["ly", "qm", "google", "cs", "sc"];
			if (!excludedIds.includes(to.params.id)) {
				next({ name: "login", params: { id: "ly" } });
			} else {
				next();
			}
		},
	},
*/
